import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About Me`}</h1>
    <p>{`Hello, Thank you for checking out my portfolio! I'm a Software Engineer who has a background in game design and specializes in working on game feel and gameplay systems. `}</p>
    <div style={{
      "textAlign": "left"
    }}>
      <ul>
        <li parentName="ul">{`9 years of experience programming and designing video games.`}</li>
        <li parentName="ul">{`Skilled in C#, Java, Swift, Objective C.`}</li>
        <li parentName="ul">{`Shipped multiple Unity games across Android, iOS, Steam, Switch, Xbox One.  `}</li>
      </ul>
    </div>
    <h3>{`Current Roles:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://finji.co/"
      }}>{`Unity Programmer at Finji`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      